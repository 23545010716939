<template>
  <v-container
    fluid
    fill-height
  >
    <v-menu
      open-on-hover
      bottom
      right
      offset-y
      transition="slide-y-transition"
      style="position: absolute; right: 0; top: 0; padding: 15px;"
    >
      <router-link
        v-ripple
        slot="activator"
        class="toolbar-items"
        to=""
      >
        <v-badge
          color="error"
          overlap
        >
          <span style="text-transform: uppercase; color: rgba(0,0,0,.87);">{{ selectedLocale.locale }}</span>
        </v-badge>
      </router-link>
      <v-card>
        <v-list dense>
          <v-list-tile
            v-for="language in languages"
            :key="language.locale"
            @click="changeLanguage(language)"
          >
            <v-list-tile-title
              v-text="$t(language.name)"
            />
          </v-list-tile>
        </v-list>
      </v-card>
    </v-menu>
    <v-layout
      align-center
      justify-center
    >
      <v-flex
        xs12
        sm8
        md4
      >
        <v-card class="elevation-12">
          <v-toolbar
            :color="'primary'"
            flat
          >
            <v-toolbar-title style="text-align: center;">
              <img
                v-if="!forConasi"
                src="../../assets/e_voucher_logo_white.svg"
                width="50%">
              <img
                v-else
                src="../../assets/e_voucher_logo_white_conasi.png"
                width="50%">
            </v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-form
              ref="form"
              v-model="valid"
              lazy-validation
            >
              <div style="position: relative;">
                <v-text-field
                  :rules="[ruleRequiredValue, ruleValidPassword]"
                  v-model="newPassword"
                  :label="$t('forgotPassword.newPassword')"
                  :name="$t('forgotPassword.newPassword')"
                  :type="isShowNewPassword ? 'text' : 'password'"
                  prepend-icon="mdi-lock"
                  class="required"
                  @keyup.enter="onConfirmResetPassword($event)"
                />
                <v-icon
                  style="position: absolute; right: 0; top: 25%; cursor: pointer;"
                  @mousedown="showPassword('old')"
                  @mouseleave="hidePassword('old')"
                  @mouseup="hidePassword('old')">{{ isShowNewPassword ? 'mdi-eye-off' : 'mdi-eye' }}</v-icon>
              </div>
              <div style="position: relative;">
                <v-text-field
                  :rules="[ruleRequiredValue, ruleSamePassword]"
                  v-model="confirmNewPassword"
                  :label="$t('forgotPassword.confirmPassword')"
                  :name="$t('forgotPassword.confirmPassword')"
                  :type="isShowConfirmPassword ? 'text' : 'password'"
                  prepend-icon="mdi-lock"
                  class="required"
                  @keyup.enter="onConfirmResetPassword($event)"
                />
                <v-icon
                  style="position: absolute; right: 0; top: 25%; cursor: pointer;"
                  @mousedown="showPassword('new')"
                  @mouseleave="hidePassword('new')"
                  @mouseup="hidePassword('new')">{{ isShowConfirmPassword ? 'mdi-eye-off' : 'mdi-eye' }}</v-icon>
              </div>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="success"
              @click="onConfirmResetPassword($event)">{{ $t('common.confirm') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
    <loading-bar :is-loading="isLoading"/>
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar" />
  </v-container>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import { FOR_CONASI_TARGET } from 'utils/constants'
import ToastType from 'enum/toastType'
import functionUtils from 'utils/functionUtils'
import validationRules from 'utils/validationRules'
export default {
  data: () => ({
    valid: true,
    newPassword: null,
    confirmNewPassword: null,
    isLoading: false,
    isShowNewPassword: false,
    isShowConfirmPassword: false,
    forConasi: FOR_CONASI_TARGET
  }),
  computed: {
    ...mapGetters([
      'GET_SNACK_BAR',
      'languages',
      'selectedLocale'
    ])
  },
  methods: {
    /**
     * Show password
     */
    showPassword (type) {
      if (type === 'old') {
        this.isShowNewPassword = true
      } else {
        this.isShowConfirmPassword = true
      }
    },
    /**
     * Hide password
     */
    hidePassword (type) {
      if (type === 'old') {
        this.isShowNewPassword = false
      } else {
        this.isShowConfirmPassword = false
      }
    },
    // Require value
    ruleRequiredValue (value) {
      return validationRules.ruleRequiredValue(value)
    },
    ruleValidPassword (value) {
      return validationRules.ruleValidPassword(value)
    },
    ruleSamePassword (value) {
      // Validate old and new password
      return validationRules.ruleSamePassword(value, this.newPassword)
    },
    /**
     * Change language
     */
    changeLanguage (language) {
      this.$i18n.locale = language.locale
      this.$store.dispatch('changeLanguage', language)
    },
    // Validate
    validate () {
      if (this.$refs.form.validate()) {
        let token = this.$route.params.token
        let uid = this.$route.params.uid
        let filter = {
          newPassword: this.newPassword,
          confirmPassword: this.confirmNewPassword,
          uidb64: uid,
          token: token
        }
        this.isLoading = true
        this.CONFIRM_RESET_PASSWORD(filter).then(
          function () {
            this.isLoading = false
            this.ON_SHOW_TOAST({
              'message': this.$t('common.success'),
              styleType: ToastType.SUCCESS
            })
            this.$router.push({
              path: '/login'
            })
          }.bind(this)
        ).catch(
          function (error) {
            this.isLoading = false
            if (!functionUtils.isNull(error.response.data.message)) {
              let errorText = error.response.data.message
              this.ON_SHOW_TOAST({
                'message': this.$t(`${errorText}`),
                styletype: ToastType.ERROR
              })
            } else {
              this.ON_SHOW_TOAST({
                'message': this.$t('login.somethingWentWrong'),
                'styleType': ToastType.ERROR
              })
            }
          }.bind(this)
        )
      }
    },
    /**
     * Go dashboard
     */
    onConfirmResetPassword: function (event) {
      event.preventDefault()
      this.validate()
    },
    ...mapActions([
      'ON_SHOW_TOAST',
      'CONFIRM_RESET_PASSWORD'
    ])
  }
}
</script>
<style lang="scss" scoped>
.translate-toolbar .translate-toolbar__content{
  justify-content: flex-end !important;
  float: right;
}
/deep/.required label::after {
  content: " *";
  color: red;
}

.v-card__actions {
  display: flex;
  justify-content: flex-end;
}
</style>
